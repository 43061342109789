import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Row, Col, Visible, Hidden } from 'react-grid-system';
import { useLocation } from '@reach/router';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { graphql, Link } from 'gatsby';

import './Post.scss';

import website from 'config/website';
import { useAllContentfulArticle } from '../../lib/queries/article.queries';

import { Icon } from 'components/Icon';
import { PrimaryLayout } from 'components/PrimaryLayout';
import { RichText } from 'components/RichText';
import { SEO } from 'components/SEO';
import { PrimaryArticleHorizontal } from 'components/PrimaryArticleHorizontal';
import { SecondaryArticleHorizontal } from 'components/SecondaryArticleHorizontal';
import { SectionHeaderSecondary } from 'components/SectionHeaderSecondary';
import { SectionHeaderTertiary } from 'components/SectionHeaderTertiary';
import { SideArticleList } from 'components/SideArticleList';
import * as routes from '../../utils/routes';
import { sortArticleByCount } from 'utils/helpers';
import { BACKEND_BASE_URL } from '../../constants';

import { YoutubePlayer } from 'components/YoutubePlayer';

const getDate = (publishDate: string): string => {
  const dateTime = new Date(publishDate);
  return `${dateTime.toLocaleString('default', {
    month: 'long',
  })} ${dateTime.getDate()}, ${dateTime.getFullYear()}・`;
};

const getReadTimeEstimate = (rawData: string): string => {
  const readTime = Math.round(
    documentToPlainTextString(JSON.parse(rawData)).split(' ').length / 200
  );
  return ` ${readTime === 0 ? 1 : readTime} min read`;
};

export const query = graphql`
  query ($slug: String!, $categorySlug: String!, $subcategorySlug: String!) {
    contentfulArticle(
      slug: { eq: $slug }
      subcategory: { id: { ne: null }, category: { slug: { ne: null } } }
    ) {
      id
      title
      publishedDate(formatString: "Do MMMM, YYYY")
      richTextBam {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 750)
          }
        }
      }
      videoUrl
      createdAt
      subtitle
      videoCaption
      subcategory {
        name
        slug
        category {
          name
          slug
        }
      }

      thumbnailBam {
        data {
          target {
            fields {
              title
              description
              file
            }
            sys {
              id
              linkType
              type
            }
          }
        }
      }
      isSinglePage
      metaTitle
      metaDescription
    }

    allCategoryRelatedArticles: allContentfulArticle(
      filter: {
        subcategory: {
          id: { ne: null }
          category: { slug: { eq: $categorySlug } }
          slug: { ne: $slug }
        }
      }
      sort: { fields: publishedDate, order: DESC }
      limit: 10
    ) {
      edges {
        node {
          id
          slug
          title
          subtitle
          subcategory {
            slug
            category {
              id
              name
              slug
              featuredOnHomePage
              sectionMarkerColor
              teaserText {
                teaserText
              }
            }
          }
          publishedDate(fromNow: true)
          thumbnailBam {
            data {
              target {
                fields {
                  title
                  description
                  file
                }
                sys {
                  id
                  linkType
                  type
                }
              }
            }
          }
          richTextBam {
            raw
          }
        }
      }
    }
    allSubcategoryRelatedArticles: allContentfulArticle(
      filter: {
        subcategory: { id: { ne: null }, slug: { eq: $subcategorySlug } }
        slug: { ne: $slug }
      }
      sort: { fields: publishedDate, order: DESC }
      limit: 10
    ) {
      edges {
        node {
          id
          slug
          title
          subtitle
          subcategory {
            slug
            category {
              id
              name
              slug
              featuredOnHomePage
              sectionMarkerColor
              teaserText {
                teaserText
              }
            }
          }
          publishedDate(fromNow: true)
          thumbnailBam {
            data {
              target {
                fields {
                  title
                  description
                  file
                }
                sys {
                  id
                  linkType
                  type
                }
              }
            }
          }
          richTextBam {
            raw
          }
        }
      }
    }
  }
`;

const TopPicksForYouHeader: FunctionComponent = () => {
  const title = 'Top Picks For You';
  return (
    <>
      <Hidden xs>
        <SectionHeaderSecondary title={title} />
      </Hidden>
      <Visible xs>
        <SectionHeaderTertiary
          title={title}
          iconName="categories-link-prefix"
        />
      </Visible>
    </>
  );
};

type TopPicksForYouArticleProps = {
  node: any;
  isSponsored: boolean;
  className: string;
};

const TopPicksForYouArticle: FunctionComponent<TopPicksForYouArticleProps> = ({
  node,
  isSponsored,
  className,
}) => (
  <>
    <Hidden xs>
      <PrimaryArticleHorizontal
        image={node.thumbnailBam}
        categoryLabel={node.subcategory.name}
        header={node.title}
        articleLink={node.slug}
        categoryLink={`${node.subcategory.category.slug}/${node.subcategory.slug}`}
        articleText={documentToPlainTextString(node.richTextBam.raw)}
        isSponsored={isSponsored}
        className={className}
      />
    </Hidden>
    <Visible xs>
      <SecondaryArticleHorizontal
        image={node.thumbnailBam}
        categoryLabel={node.subcategory.name}
        header={node.title}
        articleLink={node.slug}
        categoryLink={`${node.subcategory.category.slug}/${node.subcategory.slug}`}
        isSponsored={isSponsored}
        className={className}
      />
    </Visible>
  </>
);

type PostProps = {
  data: any;
  pageContext: {
    articleAllTimeViewCountMap: {
      [articleId: string]: number;
    };
    articleMonthlyViewCountMap: {
      [articleId: string]: number;
    };
  };
};
const Post: FunctionComponent<PostProps> = ({ data, pageContext }) => {
  const location = useLocation();
  const allContentfulArticle = useAllContentfulArticle();
  const { articleAllTimeViewCountMap, articleMonthlyViewCountMap } =
    pageContext;
  const {
    contentfulArticle,
    allCategoryRelatedArticles,
    allSubcategoryRelatedArticles,
  } = data;

  const sendEmail = () => {
    window.location.href = `mailto: ?subject=${contentfulArticle.title}&body=I thought you might find this article interesting...%0D%0A${location.href}`;
  };

  useEffect(() => {
    const environment = process.env.GATSBY_ENV;

    fetch(`${BACKEND_BASE_URL}/articleViewEvent`, {
      method: 'POST',
      body: JSON.stringify({
        id: contentfulArticle.id,
        environment,
      }),
    }).catch((e) => {
      console.error(e);
    });
  }, [contentfulArticle.id]);

  const topPicksArticles = useMemo(() => {
    const topArticles = sortArticleByCount(
      allCategoryRelatedArticles,
      articleAllTimeViewCountMap
    );
    topArticles.edges = topArticles.edges.filter(
      ({ node }) => node.id !== contentfulArticle.id
    );

    return topArticles;
  }, [
    allCategoryRelatedArticles,
    articleAllTimeViewCountMap,
    contentfulArticle.id,
  ]);

  const popularArticles = useMemo(() => {
    const articles = sortArticleByCount(
      allContentfulArticle,
      articleMonthlyViewCountMap
    );
    articles.edges = articles.edges.filter(
      ({ node }) => node.id !== contentfulArticle.id
    );

    return articles;
  }, [allContentfulArticle, articleMonthlyViewCountMap, contentfulArticle.id]);

  return (
    <PrimaryLayout>
      <SEO
        title={`${contentfulArticle.metaTitle || contentfulArticle.title} | ${
          website.titleAlt
        }`}
        pathname={location.pathname}
        desc={contentfulArticle.metaDescription || data.description}
        date={contentfulArticle.publishedDate}
        article
        banner={`${contentfulArticle.thumbnailBam?.data?.target?.fields?.file?.['en-US']?.url}`}
      />
      <Row
        style={{ margin: 0, paddingLeft: 20 }}
        className="outer-post-container"
        justify="center"
      >
        <Row style={{ margin: 0 }} className="post-container">
          <Col style={{ paddingLeft: 0, paddingRight: 20 }} md={12} lg={8}>
            <article className="post-article">
              <h1 className="post-title">{contentfulArticle.title}</h1>
              {contentfulArticle.subtitle && (
                <Visible xs>
                  <h2 className="post-subtitle-top">
                    {contentfulArticle.subtitle}
                  </h2>
                </Visible>
              )}
              {contentfulArticle.subcategory && (
                <Link
                  className="post-category"
                  to={`${routes.SiteRoutes.CATEGORIES}/${contentfulArticle.subcategory.category.slug}/${contentfulArticle.subcategory.slug}`}
                >
                  {contentfulArticle.subcategory.name}
                </Link>
              )}
              <div className="post-actions-date-reading-time-container">
                <div className="post-date-reading-time-container">
                  <p className="post-date-reading-time">
                    {getDate(contentfulArticle.createdAt)}
                  </p>
                  {contentfulArticle?.richTextBam?.raw &&
                    contentfulArticle.richTextBam.raw.length > 0 && (
                      <p className="post-date-reading-time">
                        {getReadTimeEstimate(contentfulArticle.richTextBam.raw)}
                      </p>
                    )}
                  <Icon
                    alt="lightning"
                    name="lightning"
                    className="post-lightning"
                  />
                </div>
                <div>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                    target="_blank"
                    rel="noreferrer"
                    className="post-icon"
                  >
                    <Icon alt="facebook" name="facebook-branded" />
                  </a>
                  <button
                    onClick={sendEmail}
                    className="email-icon-button post-icon"
                  >
                    <Icon alt="email" name="email-branded" />
                  </button>
                </div>
              </div>

              {contentfulArticle.subtitle && (
                <Hidden xs>
                  <h2 className="post-subtitle-bottom">
                    {contentfulArticle.subtitle}
                  </h2>
                </Hidden>
              )}
              <YoutubePlayer
                caption={contentfulArticle.videoCaption}
                videoUrl={contentfulArticle.videoUrl}
                thumbnail={contentfulArticle.thumbnailBam}
              />
              <RichText
                body={contentfulArticle.richTextBam}
                isSinglePage={contentfulArticle.isSinglePage}
                hasGoogleAd
              />
            </article>
            {topPicksArticles.edges.length > 0 && <TopPicksForYouHeader />}
            {topPicksArticles.edges
              .slice(0, 4)
              .map(({ node }, index: number) => (
                <TopPicksForYouArticle
                  key={node.slug}
                  node={node}
                  isSponsored={false}
                  className="post-top-picks-for-you-article"
                />
              ))}
          </Col>
          <Col style={{ paddingRight: 10 }} md={12} lg={4}>
            <SideArticleList
              title="Popular"
              icon="categories-title-prefix"
              allContentfulArticle={popularArticles}
            />

            <SideArticleList
              title={`More From ${contentfulArticle.subcategory.category.name}`}
              icon="categories-title-prefix"
              allContentfulArticle={allCategoryRelatedArticles}
              articleCount={20}
            />
            <SideArticleList
              title={`More From ${contentfulArticle.subcategory.name}`}
              icon="categories-title-prefix"
              allContentfulArticle={allSubcategoryRelatedArticles}
              articleCount={20}
            />
          </Col>
        </Row>
      </Row>
    </PrimaryLayout>
  );
};

export default Post;
