import React, { FunctionComponent, useState } from 'react';
import { GatsbyImage, ImageDataLike } from 'gatsby-plugin-image';
import { ImageCldy } from '../ImageCldy';

import './YoutubePlayer.scss';

export type YoutubePlayerProps = {
  videoUrl: string;
  caption?: string;
  thumbnail: ImageDataLike;
};

const YoutubePlayer: FunctionComponent<YoutubePlayerProps> = ({
  videoUrl,
  caption,
  thumbnail,
}) => {
  const [isPlaying, setIsPlaying] = useState(true);
  return (
    <div className="video-container">
      <div className="video-responsive">
        {isPlaying ? (
          <iframe
            loading="lazy"
            className="video"
            width="753"
            height="423"
            src={`${videoUrl}?modestbranding=1&rel=0&fs=0&autoplay=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            aria-haspopup={false}
            title="Youtube player"
          />
        ) : (
          <div onClick={() => setIsPlaying((prev) => !prev)}>
            <ImageCldy
              title={image.data.target.fields.title['en-US']}
              description={image.data.target.fields.description['en-US']}
              file={image.data.target.fields.file['en-US']}
              objectFit="contain"
              objectPosition="left"
              className="video-placeholder-image"
              size="medium"
              showCaption={false}
              style={{ position: 'absolute' }}
            />
            <span id="play-button" className="video-play-button"></span>
          </div>
        )}
      </div>
      {caption && <p className="video-caption">{caption}</p>}
    </div>
  );
};

export default YoutubePlayer;
