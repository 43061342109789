import React, { FunctionComponent } from 'react';

import { Divider } from '../Divider';

import './SectionHeaderSecondary.scss';

export type SectionHeaderSecondaryProps = {
  title: string;
  className?: string;
};

const SectionHeaderSecondary: FunctionComponent<SectionHeaderSecondaryProps> =
  ({ title, className = '' }) => {
    return (
      <>
        <div className={`section-content-secondary ${className}`}>
          <h1 className="section-title">{title}</h1>
        </div>
        <Divider />
      </>
    );
  };

export default SectionHeaderSecondary;
