import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import * as routes from '../../../utils/routes';
import './SecondaryArticleHorizontal.scss';
import { ImageCldy } from '../ImageCldy';

import { Charts } from '../Charts';

export type SecondaryArticleHorizontalProps = {
  image: ImageDataLike;
  imageAlt: string;
  categoryLabel: string;
  header: string;
  categoryLink: string;
  articleLink: string;
  isSponsored?: boolean;
  articleIndex?: number;
  className?: string;
};
const SecondaryArticleHorizontal: FunctionComponent<SecondaryArticleHorizontalProps> =
  ({
    image,
    imageAlt,
    categoryLabel,
    header,
    categoryLink,
    articleLink,
    isSponsored = true,
    articleIndex,
    className = '',
  }) => {
    return (
      <div className={`secondary-article-horizontal-container ${className}`}>
        {articleIndex && <Charts className="index-box" index={articleIndex} />}
        <Link
          aria-label={`${header} article link`}
          className="img-container"
          to={`${routes.SiteRoutes.ARTICLE}/${articleLink}`}
        >
          {image?.data?.target && (
            <ImageCldy
              title={image.data.target.fields.title['en-US']}
              description={image.data.target.fields.description['en-US']}
              file={image.data.target.fields.file['en-US']}
              objectFit="contain"
              className="article-img"
              size="thumbnail"
              showCaption={false}
            />
          )}
        </Link>
        <div className="content-container">
          {!isSponsored && (
            <Link
              aria-label={`${categoryLabel} Category Link`}
              to={`${routes.SiteRoutes.CATEGORIES}/${categoryLink}`}
              className="category-label"
            >
              {categoryLabel}
            </Link>
          )}
          <Link
            aria-label={`${header} article link`}
            to={`${routes.SiteRoutes.ARTICLE}/${articleLink}`}
            className="article-header"
          >
            {header}
          </Link>
          {isSponsored && <p className="informative-text">SPONSORED</p>}
        </div>
      </div>
    );
  };

export default SecondaryArticleHorizontal;
